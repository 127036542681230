<template>
  <div
   id="sale-menu" 
    v-click-outside="execute"
    class="cursor-pointer w-auto">
    <i class="mdi mdi-dots-vertical mdi-18px" @click="menuList"></i>
    <ul 
      ref="menu"
      class="bg-white list no-bullets position-absolute px-3 py-2 shadow z-index-999">
      <li class="text-sm mb-1 py-1" @click="onOpenRequest()" v-if="role === 'Admin' || role === 'Branch Admin' || role === 'Branch Admin assistant'">
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">ดูคำขอ</span>
      </li>
      <li class="text-sm py-1" v-if="currentStatus === 1 && (role === 'Admin' || (role === 'Branch Admin' && branch === '1'))" @click="onApprove()">
        <i class="mdi mdi-check mr-2"></i>
        <span class="text-gray-600">จัดการแล้ว</span>
      </li>
      <li class="text-sm py-1" v-if="currentStatus === 1 && ((role === 'Branch Admin' && branch !== '1') || role === 'Branch Admin assistant')" @click="onCancel()">
        <i class="mdi mdi-close-circle mr-2"></i>
        <span class="text-gray-600">ยกเลิกคำขอ</span>
      </li>
      <li class="text-sm py-1" v-if="currentStatus === 1 && (role === 'Admin' || (role === 'Branch Admin' && branch === '1'))" @click="onReject()">
        <i class="mdi mdi-window-close mr-2"></i>
        <span class="text-gray-600">ปฏิเสธ</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue"
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  props: {
    id: {
      type: String
    },
    currentStatus: {
      type: Number
    }
  },
  data() {
    return {
      role: "",
      branch: "",
      state: false
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    execute() {
      this.state = this.state? !this.state: this.state
      if (!this.state) {
        this.hideMenu()
      }
    },
    menuList() {
      this.state = !this.state
      if (this.state) {
        this.showMenu()
      } else {
        this.hideMenu()
      }
    },
    showMenu() {
      const el = this.$refs.menu
      el.classList.add('animation-menu-list')
    },
    hideMenu() {
      const el = this.$refs.menu
      el.classList.remove('animation-menu-list')
    },
    onApprove() {
      this.execute()
      this.$emit('menuApproveModal', { 
        status: true,
        id: this.id,
        component: 'approve-preemption'
      })
    },
    onReject() {
      this.execute()
      this.$emit('menuRejectModal', { 
        status: true,
        id: this.id,
        component: 'reject-preemption'
      })
    },
    onCancel() {
      this.execute()
      this.$emit('menuCancelModal', { 
        status: true,
        id: this.id,
        component: 'cancel-preemption'
      })
    },
    onOpenRequest() {
      this.$router.push("/request/" + this.id)
    }
  },
  mounted() {
    this.getRole()
  }
}
</script>

<style scoped>
#sale-menu {
  position:relative;
}
 
#sale-menu ul.list {
  position:absolute;
  bottom:0px;
  right:0px;
  height: fit-content;
  border-radius: 6px;
  text-align: left; 
}
ul.animation-menu-list {
  display: block !important;
  transition: all;
  -webkit-animation: fadeInDown 0.1s linear forwards; /* Chrome, Safari, Opera */
  animation: fadeInDown 0.1s linear forwards;
}
/* Standard syntax */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
</style>
